<template>
  <div class="family-compositions">
    <div ref="printMe">
      <page-header title="Composition des familles" icon="fa-solid fa-people-roof" :links="getLinks">
      </page-header>
      <div class="filter-bar">
        <b-row>
          <b-col cols="8">
            <b>Familles adhérentes ou inscrites à au moins une activité</b>
          </b-col>
          <b-col>
            <b-form-select
              id="year"
              v-model="selectedSchoolYear"
              @change="onSchoolYearChanged()"
            >
              <b-select-option :value="elt" v-for="elt in schoolYears" :key="elt.id">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-col>
        </b-row>
      </div>
      <div class="no-print">
        <div class="sub-filter text-right no-print">
          <a href @click.prevent="viewMoreFilters = !viewMoreFilters">
            <span v-if="viewMoreFilters">Moins de filtres</span>
            <span v-else>Plus de filtres</span>
          </a>
        </div>
        <div class="sub-filter" v-if="viewMoreFilters">
          <b-form-checkbox id="showAdhesions" v-model="showAdhesions" v-if="hasPerm('adhesions.view_adhesion')">
            Adhésions
          </b-form-checkbox>
          <b-form-checkbox id="showActivities" v-model="showActivities" v-if="hasPerm('activities.view_activity')">
            Activités
          </b-form-checkbox>
          <div v-if="showActivities">
            <activities-select @change="onActivitiesChanged"></activities-select>
          </div>
        </div>
        <div class="sub-filter no-print" v-if="viewMoreFilters">
          <b-form-checkbox id="showYouthHomes" v-model="showYouthHomes" v-if="hasPerm('youth.view_youthhome')">
            Accueil de loisirs
          </b-form-checkbox>
          <b-row v-if="showYouthHomes">
            <b-col>
              <youth-home-filter
                :block="false"
                :school-year="selectedSchoolYear"
                @changed="onFilterChanged"
              ></youth-home-filter>
            </b-col>
            <b-col cols="3">
              <date-frame-selector @change="onDateFrameChanged"></date-frame-selector>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="text-right no-print" style="margin-top: 2px;">
        <a href @click.prevent="loadFamilies" class="btn btn-primary">
          <i class="fa fa-refresh"></i> Mettre à jour
        </a>
      </div>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div class="individuals-list" v-if="!isLoading(loadingName)" ref="excelMe">
        <x-table
          :columns="columns"
          :items="items"
          show-counter
          show-footer
          verbose-name="famille"
          verbose-name-plural="familles"
        ></x-table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import PageHeader from '@/components/Layout/PageHeader'
import LoadingGif from '@/components/Controls/LoadingGif'
import XTable from '@/components/Controls/Table/Table'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi, openDocument } from '@/utils/http'
import { makeFamilyCompositionItem } from '@/types/stats'
import { makeEntity } from '@/types/people'
import YouthHomeFilter from '@/components/Youth/YouthHomeFilter.vue'
import ActivitiesSelect from '@/components/Activities/ActivitiesSelect.vue'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'

export default {
  name: 'family-composition',
  mixins: [BackendMixin],
  components: {
    DateFrameSelector,
    ActivitiesSelect,
    YouthHomeFilter,
    PageHeader,
    LoadingGif,
    XTable,
  },
  data() {
    return {
      loadingName: 'family-compositions-stats',
      items: [],
      selectedSchoolYear: null,
      schoolYears: [],
      columns: [],
      viewMoreFilters: false,
      showAdhesions: false,
      showActivities: false,
      showYouthHomes: false,
      selectedYouthHomes: [],
      selectedPeriods: [],
      selectedTypes: [],
      startDate: null,
      endDate: null,
      selectedActivities: [],
      codes: '',
    }
  },
  computed: {
    getLinks() {
      const isValid = this.selectedSchoolYear && !this.isLoading(this.loadingName)
      return [
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'far fa-file-excel',
          cssClass: isValid ? 'btn-secondary' : 'btn-secondary disabled',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: isValid ? 'btn-secondary' : 'btn-secondary disabled',
        }
      ]
    },
  },
  watch: {
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getColumns() {
      const columns = [
        {
          name: 'name',
          label: 'Famille',
          isLink: item => {
            return (item.id)
          },
          onClick: item => {
            this.showEntitySidebar(makeEntity({ id: item.id, }))
          },
          linkUrl: item => {
            return router.resolve({ name: 'families-detail', params: { entityId: item.id, }, }).href
          },
          colFooter: 'Total',
        },
        {
          name: 'membersCount',
          label: 'Adultes et enfants',
          alignCenter: true,
          number: true,
          colFooterSum: true,
        },
        {
          name: 'adultsCount',
          label: 'Adultes',
          alignCenter: true,
          number: true,
          colFooterSum: true,
        },
        {
          name: 'childrenCount',
          label: 'Enfants',
          alignCenter: true,
          number: true,
          colFooterSum: true,
        }
      ]
      if (this.hasPerm('activities.view_coreactivityinscription')) {
        columns.push({
          name: 'inscriptionsCount',
          label: 'Inscrits aux activités',
          alignCenter: true,
          number: true,
          colFooterSum: true,
          isLink: item => {
            return (item.id)
          },
          linkUrl: item => {
            return router.resolve(
              {
                name: 'families-detail',
                params: { entityId: item.id, },
                query: { tab: 'activities', },
              }
            ).href
          },
        })
      }
      if (this.hasPerm('youth.view_youthhomeactivityinscription')) {
        columns.push({
          name: 'youthInscriptionsCount',
          label: 'Inscrits aux accueils',
          alignCenter: true,
          number: true,
          colFooterSum: true,
          isLink: item => {
            return (item.id)
          },
          linkUrl: item => {
            return router.resolve(
              {
                name: 'families-detail',
                params: { entityId: item.id, },
                query: { tab: 'youth', },
              }
            ).href
          },
        })
      }
      return columns
    },
    async onLoaded() {
      this.columns = this.getColumns()
      await this.loadSchoolYears()
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/active-school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
        if (this.schoolYears.length === 1) {
          this.selectedSchoolYear = this.schoolYears[0]
        } else {
          const schoolYearId = +(this.$route.query.schoolYear || 0)
          let index = this.schoolYears.map(elt => elt.id).indexOf(schoolYearId)
          if (index >= 0) {
            this.selectedSchoolYear = this.schoolYears[index]
          } else {
            this.selectedSchoolYear = null
          }
        }
        await this.loadFamilies()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadFamilies() {
      if (this.selectedSchoolYear) {
        this.startLoading(this.loadingName)
        let url = '/stats/api/family-composition/' + this.selectedSchoolYear.id + '/?'
        if (this.viewMoreFilters) {
          url += '&adhesions=' + (this.showAdhesions ? '1' : '0')
          url += '&activities=' + (this.showActivities ? '1' : '0')
          if (this.selectedActivities.length > 0) {
            url += '&activity-ids=' + this.selectedActivities.map(elt => '' + elt.id).join('-')
          }
          url += '&youth-homes=' + (this.showYouthHomes ? '1' : '0')
          if (this.selectedYouthHomes) {
            url += '&youth-home-ids=' + this.selectedYouthHomes.map(elt => '' + elt.id).join('-')
            url += '&type-ids=' + this.selectedTypes.map(elt => '' + elt.id).join('-')
            url += '&period-ids=' + this.selectedPeriods.map(elt => '' + elt.id).join('-')
            if (this.startDate) {
              url += '&start-date=' + this.startDate
            }
            if (this.endDate) {
              url += '&end-date=' + this.endDate
            }
          }
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.items = resp.data.map(elt => makeFamilyCompositionItem(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    async onSchoolYearChanged() {
      let schoolYearId = this.selectedSchoolYear ? this.selectedSchoolYear.id : 0
      let queryArgs = { ...this.$route.query, }
      queryArgs.schoolYear = schoolYearId
      await this.$router.replace({ query: queryArgs, })
      await this.loadFamilies()
    },
    async excelMe() {
      if (this.selectedSchoolYear) {
        const docUrl = '/documents/table-to-excel/<key>/'
        const docSlug = 'compositions-familles-' + this.selectedSchoolYear.name.replace('/', '-')
        const docContent = this.$refs.excelMe.innerHTML.toString()
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async printMe() {
      if (this.selectedSchoolYear) {
        let docUrl = '/documents/standard/<key>/pdf/'
        const docSlug = 'compositions-familles-' + this.selectedSchoolYear.name.replace('/', '-')
        const docContent = this.$refs.printMe.innerHTML.toString()
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onFilterChanged(event) {
      this.selectedYouthHomes = event.youthHomes
      this.selectedPeriods = event.periods
      this.selectedTypes = event.seanceTypes
    },
    onActivitiesChanged(event) {
      this.selectedActivities = event.choices
    },
    onDateFrameChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
    },
  },
}
</script>

<style lang="less">
  .filter-bar {
    margin-bottom: 10px;
  }
  .filter-bar h3 {
    margin-top: 5px;
    text-align: right;
    font-weight: bolder;
  }
  .sub-filter {
    background: #ccc;
    padding: 10px;
  }
</style>
